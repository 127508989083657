import { supabase } from "@lagora/utils/supabase";
import ky from "ky";

export async function getHttpClient() {
  const { data } = await supabase.auth.getSession();

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${data.session?.access_token}`);

  return ky.extend({
    headers,
  });
}
